import "bootstrap/dist/css/bootstrap.min.css";
import "../custom.css";
import React from "react";
import BackgroundImage from "../components/BackgroundImage";
import Layout from "../components/Layout";
import WhatWeDo from "../components/WhatWeDo";
import Seo from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="The Children's Educational Trust" />
      <BackgroundImage />
      <WhatWeDo />
    </Layout>
  );
};

export default IndexPage;
