import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

const BackgroundImage = () => {
  return (
    <>
      <StaticImage src="../images/school_children.jpeg" style={style} />
      <Text>Helping Children Realise Their Potential</Text>
    </>
  );
};

const style = {
  width: "100%",
  height: "100vh",
};

const Text = styled.div`
  position: absolute;
  top: 70%;
  left: 40%;
  transform: translate(-50%, -50%);
  padding: 0 8px;
  color: white;
  font-size: 52px;
  line-height: 70px;
  font-weight: 600;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  @media (min-width: 992px) {
    top: initial;
    left: initial;
    transform: initial;
    text-align: center;
    font-size: 78px;
    width: 100%;
    bottom: 1rem;
    padding: 10rem 10rem;
    background-color: initial;
  }
`;

export default BackgroundImage;
