import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Heading } from "../styles";
import PageIntroduction from "./PageIntroduction";

const WhatWeDo = () => {
  return (
    <div>
      <Container>
        <PageIntroduction
          title="What we do"
          body="
            The Children’s Educational Trust (“CET”) was established in 2010. We
            started out with a sole aim to assist schools and communities in
            enabling all school children, regardless of socio-economic status,
            to have access to appropriate Information Technology to enable them
            to develop the necessary skills within educational establishments
            and realise their potential through e-learning.
          "
          noHeaderSpacing
        />
        <Row noGutters className="mt-5 mt-lg-0">
          <Col xs="12" lg="6">
            <StyledStaticImage>
              <StaticImage
                src="../images/children_studying.jpeg"
                style={StaticImageStyle}
              />
            </StyledStaticImage>
          </Col>
          <Col xs="12" lg="6">
            <BoxWithText>
              <BoxWithTextHeading>Sponsorship Success</BoxWithTextHeading>
              <p>
                Since that time, the scope of the Trust has evolved
                significantly and with an ever increasing focus being placed on
                children’s health and well-being, we have become actively
                involved in approving sponsorships for outdoor recreation
                grounds, play areas and sports facilities, all of which prove to
                have a dramatic and positive impact on learning motivations.
              </p>
              <p>
                Through the continued and generous support of our sponsors, we
                are now able to assist schools and communities far beyond
                technology equipment.
              </p>
            </BoxWithText>
          </Col>
          <Col xs="12" lg={{ span: "6", order: "last" }}>
            <StyledStaticImage>
              <StaticImage
                src="../images/children_laptop.jpeg"
                style={StaticImageStyle}
              />
            </StyledStaticImage>
          </Col>
          <Col xs="12" lg="6">
            <BoxWithTextAlternate>
              <BoxWithTextHeading>International Reach</BoxWithTextHeading>
              <p>
                Most recently, The Children’s Educational Trust has now expanded
                its reach overseas and through the provision of reconditioned
                Computer Hardware is currently assisting in delivering much
                needed e-learning platforms into schools in Kenya, Africa
              </p>
            </BoxWithTextAlternate>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const StaticImageStyle = {
  height: "100%",
};

const BoxWithText = styled.div`
  margin-bottom: 3rem;
  @media (min-width: 992px) {
    background-color: ${({ theme: { colors } }) => colors.green};
    padding: 1rem 2rem;
    color: white;
    min-height: 280px;
    height: 100%;
    margin: initial;
  }
`;

const StyledStaticImage = styled.div`
  height: 147px;

  @media (min-width: 992px) {
    height: 100%;
  }
`;

const BoxWithTextHeading = styled(Heading)`
  @media (min-width: 992px) {
    text-align: left;
    color: white;
  }
`;

const BoxWithTextAlternate = styled(BoxWithText)`
  @media (min-width: 992px) {
    background-color: ${({ theme: { colors } }) => colors.blue};
  }
`;

export default WhatWeDo;
